import React from "react"

import {StaticImage} from "gatsby-plugin-image"
import Layout from "../../components/layout/layout"
import SEO from "../../components/layout/seo"

export default function Informatyzacja() {
  const seo = {
    title: "Wypożyczalnia sprzętu - projekt unijny w Medicus Dukla",
    description: "Celem projektu jest zwiększenie dostępności usług zdrowotnych poprzez świadczenie usług wypożyczalni sprzętu rehabilitacyjnego, pielęgnacyjnego i wspomagającego.",
    url: "https://medicusdukla.pl/projekty-unijne/wypozyczalnia"
  }
  return (
    <Layout marginTop={'8rem'}>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
      />
      <div data-g="container" className="u-text-center">
        <div className="projects-logos">
          <StaticImage className="a-img" src="../images/ddom/loga.png"
                       alt="fundusze europejskie, rzeczpospolita polska, podkarpackie, unia europejska"
                       formats={['auto', 'webp', 'avif']}/>

        </div>
        <br/>
        <br/>
      </div>
      <section className="o-ddom" data-g="container grid">
        <div data-g="4 6@md">
          <h1 className="a-heading-medium">
            MEDICUS-DUKLA Wypożyczalnia sprzętu rehabilitacyjnego, pielęgnacyjnego, wspomagającego dla mieszkańców
            powiatu krośnieńskiego i jasielskiego w Dukli.
          </h1>
          <h2 className={'a-heading-small'}>
            Szanowni Państwo!
          </h2>

          <p>
            Zapraszamy osoby niesamodzielne, w tym niepełnosprawne oraz opiekunów osób niesamodzielnych zamieszkałych w
            powiecie krośnieńskim i jasielskim do skorzystania z wypożyczalni sprzętu rehabilitacyjnego, pielęgnacyjnego
            i wspomagającego.
          </p>

          <p>
            Medicus-Dukla Wypożyczalnia sprzętu rehabilitacyjnego, pielęgnacyjnego, wspomagającego jest dedykowana
            osobom niesamodzielnym, potrzebującym wsparcia w codziennym funkcjonowaniu poprzez wypożyczenie
            specjalistycznie dobranego sprzętu oraz dla osób sprawujących opiekę nad osobami starszymi/potrzebującymi.
          </p>

          <h2 className={'a-heading-small'}>Dla kogo przeznaczona jest nasza oferta?</h2>
          <p>
            <ul>
              <li>
                osoby , które ze względu na wiek, stan zdrowia, niepełnosprawność wymagają opieki lub wsparcia w związku
                z niemożnością samodzielnego wykonywania co najmniej jednej z podstawowych czynności dnia codziennego,
                wymagają specjalistycznego wsparcia stosownie do rodzaju dysfunkcji.
              </li>
              <li>
                osoby pełnoletnie sprawujące opiekę nad osobami niesamodzielnymi, zależnymi- o statusie opiekuna
                faktycznego.
              </li>
            </ul>
          </p>


          <h2 className={'a-heading-small'}>Z dniem 01.01.2024r. ustala się koszt wypożyczenia sprzętu wg poniżej
            załączonego cennika.</h2>
          <p>
            <a href="/uploads/2024/wypozyczalnia/CENY WYNAJMU SPRZĘTU W WYPOŻYCZALNI.docx" target={'_blank'}>CENY WYNAJMU
              SPRZĘTU W WYPOŻYCZALNI.docx</a> <br/>

          </p>

          <h2 className={'a-heading-small'}>Kontakt</h2>

          <p>
            Tel. kontaktowy: 728 994 407 <br/>
            e-mail: wypozyczalnia.medicus@gmail.com
          </p>

          <p>
            Wypożyczalnia czynna od poniedziałku do piątku w godzinach 9:00 - 13:00 <br/>

            Osoba do kontaktu Izabela Krężałek
          </p>
        </div>

        <div data-g="4 offset-8@md 5@md">
          <StaticImage className="a-img" src="../../images/ddom/hero.png" alt="zdjęcie pacjenta" quality={80}
                       formats={['auto', 'webp', 'avif']}/>
          <h2 className={'a-heading-small'}>Dokumenty do pobrania</h2>
          <p>
            <a href="/uploads/2024/wypozyczalnia/REGULAMIN WYPOŻYCZALNI  W TRAKCIE TRWAŁOŚCI.pdf" target={'_blank'}>REGULAMIN WYPOŻYCZALNI  W TRAKCIE TRWAŁOŚCI.pdf</a> <br/>
            <a href="/uploads/2024/wypozyczalnia/załącznik nr 1- WYKAZ SPRZĘTU.pdf" target={'_blank'}>załącznik nr 1- WYKAZ SPRZĘTU.pdf</a> <br/>
            <a href="/uploads/2024/wypozyczalnia/Załącznik nr 2-FORMULARZ ZGŁOSZENIOWY.pdf" target={'_blank'}>Załącznik nr 2-FORMULARZ ZGŁOSZENIOWY.pdf</a> <br/>
            <a href="/uploads/2024/wypozyczalnia/Załącznik nr 3 -wzór upoważnienia.pdf" target={'_blank'}>Załącznik nr 3 -wzór upoważnienia.pdf</a><br/>
            <a href="/uploads/2024/wypozyczalnia/Załącznik nr 4 -Zaświadczenie lekarskie.pdf" target={'_blank'}>Załącznik nr 4 -Zaświadczenie lekarskie.pdf</a><br/>
            <a href="/uploads/2024/wypozyczalnia/Załącznik nr 7 RODO.pdf" target={'_blank'}>Załącznik nr 7 RODO.pdf</a><br/>
          </p>
        </div>
      </section>
      <div data-g="container" className="u-text-center">
        <br/><br/><br/><br/>
        <img className="a-img nfz" src="/images/homepage/nfz.png" alt="nfz"/>
      </div>
    </Layout>
  )
}
